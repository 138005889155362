.image {
	width: 30px;
}

.subscribeBtn {
	background-color: #0078b6;
}

.App {
	text-align: center;
	height: 5000px;
}

.scrollTop {
	position: fixed;
	width: 40px;
	bottom: 20px;
	align-items: center;
	height: 40px;
	justify-content: center;
	z-index: 1000;
	cursor: pointer;
	animation: fadeIn 0.3s;
	transition: opacity 0.4s;
	opacity: 0.5;
}

.scrollTop:hover {
	opacity: 1;
}

.footer_company_details {
	margin-top: -15px;
}
.custImg > span > img {
	position: absolute;
	inset: 0px;
	box-sizing: border-box;
	padding: 0px;
	border: none;
	margin: auto;
	display: block;
	width: 0px;
	height: 0px;
	min-width: 100%;
	max-width: 100%;
	min-height: 100%;
	max-height: 100%;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 0.5;
	}
}

.footer {
	margin-left: 20px;
}

@media only screen and (min-width: 1920px) {
	.width {
		width: 100%;
		max-width: 1650px;
		padding-left: 2.5%;
		padding-right: 2.5%;
		margin-left: auto;
		margin-right: auto;
	}
}
@media only screen and (max-width: 768px) {
	.mbcopyright {
		padding-top: 14px;
	}
}
@media only screen and (max-width: 638px) {
	.aboutm {
		margin-left: 0 !important;
		width: 100%;
	}
}

@media (max-width: 768px) {
	.copyRightWrapper {
		flex-direction: column;
		align-items: center;
	}
}

@media only screen and (min-width: 1280px) and (max-width: 2560px) {
	.copyRightBottom {
		margin-bottom: 0px !important;
	}
}

@media screen and (min-width: 480px) and (max-width: 638px) {
	.footerResponsive {
		display: flex;
		flex-wrap: wrap;
	}
}
